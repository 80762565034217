// extracted by mini-css-extract-plugin
export var roomHeader = "gallery-module--room-header--1Awsd";
export var headerTitle = "gallery-module--header-title--MtX6T";
export var headerBtn = "gallery-module--header-btn--ynajO";
export var pictureSection = "gallery-module--picture-section--BKJIK";
export var contactInfo = "gallery-module--contact-info--sc5CY";
export var contactDescription = "gallery-module--contact-description--CjYqd";
export var sectionTitle = "gallery-module--section-title--7BUVC";
export var row = "gallery-module--row--gsLc9";
export var column = "gallery-module--column--837KP";
export var img = "gallery-module--img--VxxbR";