import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"

import * as galleryStyles from '../styles/gallery.module.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import { Container, Row, Col } from 'react-bootstrap';

import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";


const Gallery = ({ data, pageContext }) => {

  const { gallery } = pageContext;

  console.log(gallery.name);

  return (
    <Layout>
        <div>
            <section className={galleryStyles.roomHeader}>
              <h1 className={galleryStyles.headerTitle}>{gallery.name}</h1>
              <Link
                to="/galleries"
                className={galleryStyles.headerBtn}
              >
                {'<'} Back to Galleries
              </Link>
            </section>

            <section className={galleryStyles.pictureSection}>
              <Container>
                <SimpleReactLightbox>
                  <SRLWrapper>
                    <div className={galleryStyles.row}>
                      {data.allFile.edges.map(( { node }) => (
                        <div key={node.id} className={galleryStyles.column}>
                          <a href={node.publicURL}>
                            <GatsbyImage className={galleryStyles.img} image={node.childImageSharp.gatsbyImageData} alt={node.base.split('-').join(' ').split('.')[0]} />
                          </a>
                        </div>
                      ))}
                    </div>
                  </SRLWrapper>
                </SimpleReactLightbox>
                
              </Container>
              
            </section>

            <section className={galleryStyles.contactInfo}>
              {
                gallery.name === "commissions" ?
                <p className={galleryStyles.contactDescription}>I am currently taking commission work!</p> :
                null
              }
              <h2 className={galleryStyles.sectionTitle}>Contact Us</h2>
              <p>andreartvegas@gmail.com</p>
              
            </section>
        </div>
    </Layout>
  )
}

export default Gallery;

export const pageQuery = graphql`
  query ($galleryname: String!) {
    allFile(filter: {relativeDirectory: {eq: $galleryname }}) {
      edges {
        node {
          id
          base
          publicURL
          childImageSharp {
            gatsbyImageData(
              height: 600
              width: 900
              transformOptions: {fit: COVER}
              placeholder: BLURRED
            )
          }
        }
      }
    }
  }
`